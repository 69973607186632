import React from "react";
import "./style.scss";
export default function YoutubeOverlay({
  src = "https://www.youtube.com/embed/de28vOZWSUM",
  onClickClose = () => {},
  show = false,
}) {
  function closePopup() {
    const yt = document.getElementById("youtubevid");

    const source = yt.src;
    yt.src = "";
    yt.src = source;
    onClickClose();
  }
  return (
    <div className={`youtubeoverlay ${show && "youtubeoverlay--active"}`}>
      <button className="youtubeoverlay__close" onClick={closePopup}>
        <i className="fa fa-times" aria-hidden="true"></i>
      </button>
      <iframe
        id="youtubevid"
        src={src}
        title="YouTube video player"
        // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
      ></iframe>
    </div>
  );
}
